import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Root,
  Container,
  Form,
  Divider,
  DistributorContainer,
  DistributorList,
  DistributorHeader,
  DistributorTitle,
  DistributorTooltip,
  DistributorRemoveButton,
  SuccessContainer,
} from "./EducationSessionRequest.style";

import { Typography } from "../../ui/Typography/Typography";
import { TextInput } from "../../ui/TextInput/TextInput";
import { Select } from "../../ui/Select/Select";
import { Textarea } from "../../ui/Textarea/Textarea";
import { Button } from "../../ui/Button/Button";
import { Link } from "../Link/Link";
import { ButtonLink } from "../../ui/ButtonLink/ButtonLink";
import { Icon } from "../../ui/Icon/Icon";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../ui/Tooltip/Tooltip";

import { provinces, distributors } from "../../config";

const schema = yup.object({
  salonName: yup.string().required().label("salon name"),
  salonAddress1: yup.string().required().label("salon address 1"),
  salonAddress2: yup.string().label("salon address 2"),
  province: yup.string().required(),
  city: yup.string().required(),
  postalCode: yup.string().required().label("postal code"),
  distributor: yup
    .array()
    .min(1)
    .max(3)
    .of(
      yup.object({
        _id: yup.string().required("Distributor is a required field"),
        accountNumber: yup
          .string()
          .required("Distributor account number is a required field")
          .label("account number"),
      })
    ),
  brandCarried: yup.string().required(),
  comments: yup.string(),
});

export const NewRequest = () => {
  const intl = useIntl();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: {
      salonName: "",
      salonAddress1: "",
      salonAddress2: "",
      province: "",
      city: "",
      postalCode: "",
      distributor: [{ _id: "", accountNumber: "" }],
      brandCarried: "",
      comments: "",
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "distributor",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = (data) => {
    console.log(data);
    setIsLoading(true);
    setSuccess(false);

    setTimeout(() => {
      setIsLoading(false);
      setSuccess(true);
    }, 2000);
  };

  return (
    <Root>
      {success ? (
        <SuccessContainer>
          <Typography type="h2">
            <FormattedMessage defaultMessage="Thank you for your request!" />
          </Typography>
          <Typography
            type="text-small"
            style={{ marginTop: 16, color: "#8f95a5" }}
          >
            Request #61772
          </Typography>
          <Typography type="text-primary" style={{ marginTop: 32 }}>
            <FormattedMessage defaultMessage="Your request form was successfully submitted. We will review it and get back to your shortly." />
          </Typography>
          <Button
            as={Link}
            to="/education"
            variant="auth"
            arrow={false}
            style={{ marginTop: 56 }}
          >
            <FormattedMessage defaultMessage="browse education" />
          </Button>
          <ButtonLink
            as={Link}
            to="/dashboard"
            variant="secondary"
            style={{ marginTop: 32 }}
          >
            <FormattedMessage defaultMessage="Manage Dashboard" />
          </ButtonLink>
        </SuccessContainer>
      ) : (
        <Container>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Controller
                name="salonName"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label={intl.formatMessage({
                      defaultMessage: "Salon Name",
                    })}
                    error={errors?.salonName}
                  />
                )}
              />
              <Controller
                name="salonAddress1"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label={intl.formatMessage({
                      defaultMessage: "Salon Address 1",
                    })}
                    error={errors?.salonAddress1}
                  />
                )}
              />
              <Controller
                name="salonAddress2"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label={intl.formatMessage({
                      defaultMessage: "Salon Address 2 (optional)",
                    })}
                    error={errors?.salonAddress2}
                  />
                )}
              />
              <Controller
                name="province"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    control={control}
                    label={intl.formatMessage({
                      defaultMessage: "Province",
                    })}
                    options={provinces}
                    error={errors?.province}
                  />
                )}
              />
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label={intl.formatMessage({
                      defaultMessage: "City",
                    })}
                    error={errors?.city}
                  />
                )}
              />
              <Controller
                name="postalCode"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label={intl.formatMessage({
                      defaultMessage: "Postal Code",
                    })}
                    error={errors?.postalCode}
                  />
                )}
              />
            </div>

            <Divider />

            <DistributorContainer>
              <DistributorList>
                {fields.map((item, index) => (
                  <li key={item.id}>
                    <DistributorHeader>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <DistributorTitle>
                          <FormattedMessage defaultMessage="Distributor" />{" "}
                          {index + 1}
                        </DistributorTitle>
                        <Tooltip placement="top-start">
                          <TooltipTrigger>
                            <DistributorTooltip>
                              <Icon icon="info-circle" />
                            </DistributorTooltip>
                          </TooltipTrigger>
                          <TooltipContent style={{ width: 180 }}>
                            <FormattedMessage defaultMessage="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
                          </TooltipContent>
                        </Tooltip>
                      </span>
                      {index > 0 && (
                        <DistributorRemoveButton
                          type="button"
                          onClick={() => remove(index)}
                        >
                          <Icon icon="trash-can" />
                        </DistributorRemoveButton>
                      )}
                    </DistributorHeader>
                    <Controller
                      name={`distributor.${index}._id`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          control={control}
                          label={intl.formatMessage({
                            defaultMessage: "Distributor",
                          })}
                          options={distributors()}
                          error={errors?.distributor?.[index]?._id}
                        />
                      )}
                    />
                    <Controller
                      name={`distributor.${index}.accountNumber`}
                      control={control}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          label={intl.formatMessage({
                            defaultMessage: "Distributor Account Number",
                          })}
                          error={errors?.distributor?.[index]?.accountNumber}
                        />
                      )}
                    />
                  </li>
                ))}
              </DistributorList>
              <Button
                variant="action"
                arrow={false}
                type="button"
                onClick={() => append({ _id: "", accountNumber: "" })}
              >
                <Icon icon="plus" style={{ marginRight: 6, fontSize: 16 }} />
                <FormattedMessage
                  defaultMessage="add new distributor"
                  tagName="span"
                />
              </Button>
            </DistributorContainer>

            <Divider />

            <div>
              <Controller
                name="brandCarried"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    control={control}
                    label={intl.formatMessage({
                      defaultMessage: "Brand Carried",
                    })}
                    options={[
                      {
                        label: "Label #1",
                        value: "value_1",
                      },
                      {
                        label: "Label #2",
                        value: "value_2",
                      },
                    ]}
                    error={errors?.brandCarried}
                  />
                )}
              />
              <Controller
                name="comments"
                control={control}
                render={({ field }) => (
                  <Textarea
                    {...field}
                    label={intl.formatMessage({
                      defaultMessage: "Comments",
                    })}
                    error={errors?.comments}
                    rows={4}
                  />
                )}
              />
            </div>

            <Button
              type="submit"
              variant="auth"
              arrow={false}
              disabled={isLoading || isSubmitting || !isValid}
            >
              <FormattedMessage defaultMessage="submit my request" />
            </Button>
          </Form>
        </Container>
      )}
    </Root>
  );
};
