import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "../Link/Link";

import {
  Root,
  Container,
  Logo,
  NavContainer,
  NavBlock,
  Title,
  Nav,
  Bottom,
  BottomContainer,
  BottomNav,
  BottomNavItem,
  SocialContainer,
} from "./Footer.style";

import logoSrc from "../../assets/h-logo.svg";

import { Social } from "../Social/Social";
import { brands } from "../../config/brands";

export function Footer() {
  const brandsHalfLength = Math.ceil(brands.length / 2);
  const brandsLeftSide = brands.slice(0, brandsHalfLength);
  const brandsRightSide = brands.slice(brandsHalfLength, brands.length);

  return (
    <Root>
      <Container>
        <div>
          <Logo src={logoSrc} alt="Henkel Beauty Loyalty" />
        </div>

        <NavContainer>
          <NavBlock>
            <Title>
              <FormattedMessage defaultMessage="Discover" />
            </Title>
            <Nav>
              <li>
                <Link to="/membership-benefits">
                  <FormattedMessage defaultMessage="Membership Benefits" />
                </Link>
              </li>
              <li>
                <Link to="/rewards">
                  <FormattedMessage defaultMessage="Shop Rewards" />
                </Link>
              </li>
              <li>
                <Link to="/experience?filters=%28categories%3A%21%28education%29%29">
                  <FormattedMessage defaultMessage="Education" />
                </Link>
              </li>
              <li>
                <Link to="/experience">
                  <FormattedMessage defaultMessage="Experiences" />
                </Link>
              </li>
              {/* <li>
                <Link to="/promos">
                  <FormattedMessage defaultMessage="Promos" />
                </Link>
              </li> */}
              <li>
                <Link to="/community">
                  <FormattedMessage defaultMessage="Community" />
                </Link>
              </li>
            </Nav>
          </NavBlock>
          <NavBlock>
            <Title>
              <FormattedMessage defaultMessage="Useful Links" />
            </Title>
            <Nav>
              <li>
                <Link to="/dashboard/my-account">
                  <FormattedMessage defaultMessage="My Account" />
                </Link>
              </li>
              <li>
                <Link to="/dashboard">
                  <FormattedMessage defaultMessage="My Dashboard" />
                </Link>
              </li>
              <li>
                <a
                  href="https://www.henkel-northamerica.com/contact-us/"
                  target="_blank"
                >
                  <FormattedMessage defaultMessage="Contact Us" />
                </a>
              </li>
              <li>
                <Link to="/terms">
                  <FormattedMessage defaultMessage="Terms & Conditions" />
                </Link>
              </li>
            </Nav>
          </NavBlock>
          <NavBlock>
            <Title>
              <FormattedMessage defaultMessage="Our Brands" />
            </Title>
            <Nav>
              {brandsLeftSide.map((itm) => (
                <li key={itm.id}>
                  <Link to={`/brands/${itm.slug}`}>{itm.label}</Link>
                </li>
              ))}
            </Nav>
          </NavBlock>
          <NavBlock>
            <Title className="--mobile" as="span">
              &nbsp;
            </Title>
            <Nav className="--mobile">
              {brandsRightSide.map((itm) => (
                <li key={itm.id}>
                  <Link to={`/brands/${itm.slug}`}>{itm.label}</Link>
                </li>
              ))}
            </Nav>
          </NavBlock>
        </NavContainer>

        <SocialContainer>
          <Social />
        </SocialContainer>
      </Container>

      <Bottom>
        <BottomContainer>
          <div>
            © {new Date().getFullYear()}{" "}
            <FormattedMessage defaultMessage="Henkel Corporation" />
          </div>
          <BottomNav>
            <BottomNavItem>
              <a
                href="https://www.henkel-northamerica.com/privacy-statement-na"
                target="_blank"
              >
                <FormattedMessage defaultMessage="Privacy Policy" />
              </a>
            </BottomNavItem>
            {/* <BottomNavItem>
              <button onClick={() => window.Optanon.ToggleInfoDisplay()}>
                Cookies
              </button>
            </BottomNavItem> */}
            {/* <BottomNavItem>
              <Link to="/">Accessibility Policy</Link>
            </BottomNavItem> */}
            <BottomNavItem>
              <a
                href="https://www.henkel-northamerica.com/privacy-statement-na#analytics"
                target="_blank"
              >
                <FormattedMessage defaultMessage="About Ads" />
              </a>
            </BottomNavItem>
            <BottomNavItem>
              <a
                href="https://www.henkel-northamerica.com/terms-of-use-na"
                target="_blank"
              >
                <FormattedMessage defaultMessage="Terms of Use" />
              </a>
            </BottomNavItem>
            <BottomNavItem>
              <a
                href="https://www.henkel-northamerica.com/contact-us/"
                target="_blank"
              >
                <FormattedMessage defaultMessage="Contact Us" />
              </a>
            </BottomNavItem>
            <BottomNavItem>
              <a href="https://clubhrewards.zendesk.com/" target="_blank">
                <FormattedMessage defaultMessage="Support" />
              </a>
            </BottomNavItem>
            <BottomNavItem>
              <Link to="/en-CA">
                <FormattedMessage defaultMessage="English" />
              </Link>
            </BottomNavItem>
            <BottomNavItem>
              <Link to="/fr-CA">
                <FormattedMessage defaultMessage="French" />
              </Link>
            </BottomNavItem>
          </BottomNav>
        </BottomContainer>
      </Bottom>
    </Root>
  );
}
