import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
  Root,
  Container,
  Logo,
  CtaContainer,
  Bottom,
  BottomContainer,
  BottomNav,
  BottomNavItem,
  SocialContainer,
} from "./Footer.style";

import logoSrc from "../../../assets/clubh-logo.svg";

import { Social } from "../../Social/Social";
import { Link } from "../../Link/Link";
import { Typography } from "../../../ui/Typography/Typography";
import { Button } from "../../../ui/Button/Button";

export function Footer() {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Root>
      <Container>
        <div>
          <Logo
            src={logoSrc}
            alt={intl.formatMessage({
              defaultMessage: "Henkel Beauty Loyalty",
            })}
          />
        </div>

        <CtaContainer>
          <Button type="button" onClick={() => navigate("/auth/register")}>
            <FormattedMessage defaultMessage="join now" />
          </Button>
        </CtaContainer>

        <SocialContainer>
          <Social />
        </SocialContainer>
      </Container>

      <Bottom>
        <BottomContainer>
          <Typography type="text-small">
            <FormattedMessage
              defaultMessage="© {year} Henkel Corporation"
              values={{ year: new Date().getFullYear() }}
            />
          </Typography>
          <BottomNav>
            <BottomNavItem>
              <a
                href="https://www.henkel-northamerica.com/privacy-statement-na"
                target="_blank"
              >
                <Typography type="text-small">
                  <FormattedMessage defaultMessage="Privacy Policy" />
                </Typography>
              </a>
            </BottomNavItem>
            {/* <BottomNavItem>
              <button onClick={() => window.Optanon.ToggleInfoDisplay()}>
                <Typography type="text-small">
                  <FormattedMessage defaultMessage="Cookies" />
                </Typography>
              </button>
            </BottomNavItem> */}
            {/* <BottomNavItem>
              <Link to="/">
                <Typography type="text-small">
                  <FormattedMessage defaultMessage="Accessibility Policy" />
                </Typography>
              </Link>
            </BottomNavItem> */}
            <BottomNavItem>
              <a
                href="https://www.henkel-northamerica.com/privacy-statement-na#analytics"
                target="_blank"
              >
                <Typography type="text-small">
                  <FormattedMessage defaultMessage="About Ads" />
                </Typography>
              </a>
            </BottomNavItem>
            <BottomNavItem>
              {/* <a
                href="https://www.henkel-northamerica.com/terms-of-use-na"
                target="_blank"
              >
                <Typography type="text-small">
                  <FormattedMessage defaultMessage="Terms & Conditions" />
                </Typography>
              </a> */}
              <a
                href="https://www.henkel-northamerica.com/terms-of-use-na"
                target="_blank"
              >
                <Typography type="text-small">
                  <FormattedMessage defaultMessage="Terms of Use" />
                </Typography>
              </a>
            </BottomNavItem>
            <BottomNavItem>
              <a
                href="https://www.henkel-northamerica.com/contact-us/"
                target="_blank"
              >
                <Typography type="text-small">
                  <FormattedMessage defaultMessage="Contact Us" />
                </Typography>
              </a>
            </BottomNavItem>
            <BottomNavItem>
              <a href="https://clubhrewards.zendesk.com/" target="_blank">
                <Typography type="text-small">
                  <FormattedMessage defaultMessage="Support" />
                </Typography>
              </a>
            </BottomNavItem>
            <BottomNavItem>
              <Link locale="fr-CA">
                <Typography type="text-small">
                  <FormattedMessage defaultMessage="French" />
                </Typography>
              </Link>
            </BottomNavItem>
            <BottomNavItem>
              <Link locale="en-CA">
                <Typography type="text-small">
                  <FormattedMessage defaultMessage="English" />
                </Typography>
              </Link>
            </BottomNavItem>
          </BottomNav>
        </BottomContainer>
      </Bottom>
    </Root>
  );
}
