import React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import {
  Root,
  ImageContainer,
  Image,
  Header,
  Logo,
  Close,
  ContentContainer,
  OutletContainer,
  Nav,
  NavItem,
  ScrollContainer,
} from "./AuthView.style";

import imgSrc from "../../assets/images/Alterna_Caviar_Moisture_Beauty_Duo-min.jpg";
import logoSrc from "../../assets/clubh-logo.svg";

import { Link } from "../../components/Link/Link";

export function AuthView() {
  const navigate = useNavigate();

  return (
    <Root>
      <Header>
        <Link to="/">
          <Logo src={logoSrc} alt="Henkel Beauty Loyalty" />
        </Link>
        <Close type="button" onClick={() => navigate("/")}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="1em"
            height="1em"
          >
            <path d="M.838 23.999a.837.837 0 0 1-.591-1.428l10.524-10.525L.248 1.605a.837.837 0 1 1 1.18-1.19l10.528 10.447L22.571.246a.837.837 0 0 1 1.184 1.184L13.144 12.041l10.608 10.527a.837.837 0 1 1-1.179 1.189L11.96 13.226 1.431 23.755a.836.836 0 0 1-.593.244Z" />
          </svg>
        </Close>
      </Header>

      <ImageContainer>
        <Image src={imgSrc} />
      </ImageContainer>

      <ContentContainer>
        <ScrollContainer>
          <OutletContainer>
            <Outlet />
          </OutletContainer>

          <Nav>
            <NavItem>
              <a
                href="https://www.henkel-northamerica.com/privacy-statement-na"
                target="_blank"
              >
                <FormattedMessage defaultMessage="Privacy Policy" />
              </a>
            </NavItem>
            <NavItem>
              <a
                href="https://www.henkel-northamerica.com/terms-of-use-na"
                target="_blank"
              >
                <FormattedMessage defaultMessage="T&Cs" />
              </a>
            </NavItem>
            <NavItem>
              <a href="https://clubhrewards.zendesk.com" target="_blank">
                <FormattedMessage defaultMessage="Support" />
              </a>
            </NavItem>
          </Nav>
        </ScrollContainer>
      </ContentContainer>
    </Root>
  );
}
