import React, { forwardRef } from 'react';

import { Root, Input, Label, Error } from './Textarea.style';

export const Textarea = forwardRef(({ label = ' ', error, ...props }, ref) => {
  return (
    <Root>
      <Input ref={ref} placeholder={label} {...props} />
      <Label>{label}</Label>
      {error && <Error>{error?.message}</Error>}
    </Root>
  );
});
