import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 336px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 44px;
  width: 100%;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dcdfe9;
`;

export const DistributorContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const DistributorList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  list-style: none;
`;

export const DistributorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const DistributorTitle = styled.div`
  font-size: 13px;
  font-weight: 500;
`;

export const DistributorTooltip = styled.div`
  display: flex;
  margin-left: 4px;
  color: #8f95a5;
  font-size: 17px;
`;

export const DistributorRemoveButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 20px;
`;

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 380px;
  text-align: center;
`;
