export default `
# OFFICIAL RULES AND REGULATIONS
# Club H Sweep into Style Giveaway

## 1. KEY DATES:

The Club H Sweep into Style Giveaway (the **“Giveaway”**) begins on March 18, 2024 at 12:01 am Eastern Time (“ET”) and ends on June 30, 2024 at 11:59 p.m. ET (the **“Giveaway Period”**).

## 2. ELIGIBILITY TO ENTER:

The Giveaway is open only to individuals who (i) are the owner of a salon operating in Canada (excluding Northwest Territories, the Yukon and Nunavut; the **“Territories”**), or an independent operator within a salon operating business in Canada (excluding the Territories); and (ii) a legal resident of Canada (excluding the Territories) who has reached the legal age of majority in their province or territory of residence. Employees, representatives and agents (and those with whom such persons are living, whether related or not) of Henkel Canada Corporation (**“Henkel”**) (the **“Sponsor”**), its parent companies, subsidiaries, affiliates, prize suppliers, advertising/promotion agencies and any other individual(s), entity or entities involved in the development, production, implementation, administration or fulfillment of the Giveaway (collectively, the **“Giveaway Parties”**) are not eligible to enter or win.

## 3. AGREEMENT TO BE LEGALLY BOUND BY RULES

By participating in this Giveaway, you are signifying your agreement that you have read and agree to be legally bound by these Official Rules and Regulations (the **“Rules”**).

## 4. HOW TO ENTER

NO PURCHASE NECESSARY. To participate in the Giveaway, you must successfully register to be a member of Club H in accordance with the terms and conditions of the Club H Loyalty Program (https://clubh.ca/en-CA/terms) (the **“Program”**) between January 1, 2024 and June 30, 2024 at 11:59 p.m. ET (the **“Registration Period”**).

- To participate in the Program, you must obtain a Program account (an **“Account”**).
- To obtain an Account, you must visit the website www.clubh.ca and complete the Program registration form (the **“Registration form”**) by submitting all required information, including but not limited to, all Purchasing Account Numbers (your salon business purchasing account number with an eligible Henkel direct sales representative or distributor house participating in the Program) that you would like to use in the Program, your full name, business name and address, profession, mobile number, valid email address, and Account password, and signify your agreement to the Terms.
- When completing the **Registration form**, you will also have the option to submit certain additional information to Henkel (including, but not limited to, your agreement to list your business on Henkel’s salon locator, subscribe to receive SMS communications, and information regarding your business’ opening anniversary).
- Upon submitting all required registration information, you will be registered for an Account.

Once the registration process has been successfully completed during the Registration Period, you will automatically receive one (1) entry (an “Entry”) and be entered in the Giveaway. If you would like to opt-out of the Giveaway, please send your request to that effect through our support center at https://clubhrewards.zendesk.com/hc/en-us.

## 5. ENTRY LIMIT AND CONDITIONS:

If it is discovered by the Sponsor (using any evidence or other information made available to or otherwise discovered by the Sponsor) that any person has attempted to: (i) use multiple names, multiple identities, multiple Accounts, multiple e-mail addresses and/or any automated, macro, script, robotic or other system(s) or program(s) to enter or otherwise participate in or to disrupt the Giveaway; and/or (ii) disrupt or participate in the Giveaway in any other fraudulent or misleading way, then he/she may be disqualified from the Giveaway in the sole and absolute discretion of the Sponsor. The Giveaway Parties and each of their respective agents, employees, directors, successors, and assigns (collectively, the **“Released Parties”**) are not responsible for, and accept no liability whatsoever in relation to, any late, lost, misdirected, delayed, incomplete or incompatible Entries (all of which are void). An Entry may be rejected if, in the sole and absolute discretion of the Sponsor, the Entry is not submitted and received in accordance with these Rules.

## 6. VERIFICATION

All entrants are subject to verification at any time and for any reason. The Sponsor reserves the right, in its sole and absolute discretion, to require proof of identity and/or eligibility (in a form acceptable to the Sponsor – including, without limitation, government issued photo identification): (i) for the purposes of verifying an entrant’s eligibility to participate in this Giveaway; (ii) for the purposes of verifying the eligibility and/or legitimacy of any Entry, Registration form, and/or other information provided (or purportedly provided) for the purposes of this Giveaway (including but not limited to identification for the purpose of proving that you are the Account holder); and/or (iii) for any other reason the Sponsor deems necessary, in its sole and absolute discretion, for the purposes of administering this Giveaway in accordance with the letter and spirit of these Rules. Failure to provide such proof to the complete satisfaction of the Sponsor within the timeline specified by the Sponsor may result in disqualification in the sole and absolute discretion of the Sponsor. The sole determinant of the time for the purposes of the Giveaway will be the official time-keeping device(s) used by the Sponsor.

## 7. PARTICIPATION REQUIREMENTS:

BY PARTICIPATING IN THIS GIVEAWAY, YOU AGREE TO RELEASE THE RELEASED PARTIES FROM ANY AND ALL LIABILITY IN CONNECTION WITH THE GIVEAWAY AND YOUR PARTICIPATION THEREIN AND TO INDEMNIFY THE RELEASED PARTIES AGAINST ANY AND ALL CLAIMS, DAMAGES, LIABILITIES, COSTS, AND EXPENSES ARISING FROM YOUR PARTICIPATION IN THE GIVEAWAY. BY PARTICIPATING IN THIS GIVEAWAY, YOU ALSO AGREE THAT EACH ENTRY (AND EACH INDIVIDUAL COMPONENT THEREOF) AND ALL OTHER ASPECTS OF YOUR PARTICIPATION IN THIS GIVEAWAY COMPLIES WITH ALL CONDITIONS STATED IN THESE RULES. THE RELEASED PARTIES WILL BEAR NO LIABILITY WHATSOEVER REGARDING: (I) THE USE OF YOUR ENTRY (OR ANY COMPONENT THEREOF); (II) PARTICIPATION IN ANY GIVEAWAY-RELATED ACTIVITIES; (III) ANY USE, COLLECTION, STORAGE AND DISCLOSURE OF ANY PERSONAL INFORMATION; AND/OR (IV) IF DECLARED THE WINNER, THE PRIZE (INCLUDING ANY USE OR MISUSE OF THE PRIZE). THE RELEASED PARTIES SHALL BE HELD HARMLESS BY YOU IN THE EVENT IT IS DISCOVERED THAT YOU HAVE DEPARTED FROM OR NOT OTHERWISE FULLY COMPLIED WITH ANY OF THESE RULES. THIS RELEASE AND INDEMNITY SHALL CONTINUE IN FORCE FOLLOWING THE TERMINATION OF THE GIVEAWAY AND/OR AWARDING OF ANY PRIZE.

By participating in the Giveaway, each entrant hereby warrants and represents that any Giveaway-related information he/she submits does not contain any reference to any identifiable third parties, unless consent has been obtained from all such individuals and their parent/legal guardian if they are under the age of majority in their jurisdiction of residence; and will not give rise to any claims whatsoever, including, without limitation, claims of infringement, invasion of privacy or publicity, or infringe on any rights and/or interests of any third party.

## 8. PRIZE:

There are thirty-six (36) prizes (each, a **“Prize”**) available to be won in the Giveaway, and each Prize is regionally allocated as indicated in the table below. The Prizes consist of:

| Prize Type | Number of such Prizes, and Regional Allocation | Prize Description |
| ---------- | ---------------------------------------------- | ----------------- |
| “Prize 1” | Twelve (12), which are regionally allocated as follows: (i) two (2) for Ontatio; (ii) two (2) for Quebec; and (iii) one (1) for each other province. | $100 CAD credit towards a Sponsor’s brand of colour products (as selected by the Sponsor in its sole discretion on the basis of the winner’s previous purchases, if any, as per the Sponsor’s official records).
| “Prize 2” | Twelve (12), which are regionally allocated as follows: (i) two (2) for Ontatio; (ii) two (2) for Quebec; and (iii) one (1) for each other province. | $100 CAD credit towards a Sponsor’s brand of styling products (as selected by the Sponsor in its sole discretion on the basis of the winner’s previous purchases, if any, as per the Sponsor’s official records).
| “Prize 3” | Twelve (12), which are regionally allocated as follows: (i) two (2) for Ontatio; (ii) two (2) for Quebec; and (iii) one (1) for each other province. | $100 CAD credit towards a Sponsor’s brand of styling products (as selected by the Sponsor in its sole discretion on the basis of the winner’s previous purchases, if any, as per the Sponsor’s official records).

The total value of all Prizes is $3,600 CAD.

Each Prize must be accepted as awarded and is not transferable, assignable or convertible to cash (except as may be specifically permitted by Sponsor in its sole and absolute discretion). No substitutions are permitted, except at Sponsor’s option. The Sponsor reserves the right to substitute any Prize in whole or in part in the event that all or any component of such Prize is unavailable with the Prize or Prize component(s) of equal or greater retail value, including, without limitation, but solely at the Sponsor’s sole discretion, a cash award. All characteristics and features of the Prize, except as otherwise explicitly stated above, are at the Sponsor’s sole and absolute discretion. The Prize winner is solely responsible for all costs not expressly described herein. The Prize winner is solely responsible for reading and following all instructions, directions for use and warnings that are associated with each component of the Prize.

None of the Released Parties makes any representation or offers any warranty, express or implied, as to the quality or fitness of any Prize awarded in connection with the Giveaway. To the fullest extent permitted by applicable law, each confirmed winner understands and acknowledges that he or she may not seek reimbursement or pursue any legal or equitable remedy from either the Sponsor or any of the other Released Parties should his/her Prize fail to be fit for its purpose or is in any way unsatisfactory. For greater certainty and the avoidance of any doubt, by accepting the Prize, the confirmed winner agrees to waive all recourse against the Sponsor and all of the other Released Parties if the Prize or a component thereof does not prove satisfactory, either in whole or in part.

## 9. ELIGIBLE WINNER SELECTION PROCESS:

On July 10, 2024 in Mississauga, ON at approximately 11:00 am ET (the **“Selection Date”**), the Sponsor will perform ten (10) random draws; one (1) random draw per province from among all eligible Entries allocated to such province (each Entry will be allocated to the a province on the basis of the business address associated with the Account that associated with the Entry, as determined by the Sponsor in its sole discretion on the basis of its official records) to select the winners of the Prizes allocated to such province. The odds of winning depend on the number of eligible Entries per province submitted and received in accordance with these Rules during the Giveaway Period.

## 10. ELIGIBLE WINNER NOTIFICATION PROCESS:

The Sponsor or its designated representative will make a minimum of two (2) attempts to contact the potential winner via email or by phone within two (2) business days of the Selection Date. If a potential winner cannot be contacted within such time, or if there is a return of any notification as undeliverable; then he/she may, in the sole and absolute discretion of the Sponsor, be disqualified (and, if disqualified, will forfeit all rights to the Prize) and the Sponsor reserves the right, in its sole and absolute discretion and time permitting, to randomly select an alternate potential winner from among the remaining applicable eligible Entries submitted and received in accordance with these Rules during the Giveaway Period (in which case the foregoing provisions of this section shall apply to such newly selected potential winner).

## 11. ELIGIBLE WINNER CONFIRMATION PROCESS:

NO ONE IS A WINNER UNLESS AND UNTIL THE SPONSOR OFFICIALLY CONFIRMS HIM/HER AS THE WINNER IN ACCORDANCE WITH THESE RULES. Before being declared A CONFIRMED PRIZE winner, each potential winner will be required to correctly answer a mathematical skill-testing question without mechanical or other aid (which may, in the sole and absolute discretion of the Sponsor, be administered online, by email or other electronic means, by telephone, or in the Sponsor’s form of declaration and release). By accepting a Prize, each applicable winner: (i) confirms compliance with these Rules; (ii) acknowledges acceptance of the Prize (as awarded); (iii) releases the Released Parties from any and all liability in connection with this Giveaway, his/her participation therein and/or the awarding and use/misuse of the Prize or any portion thereof; (iv) agrees to indemnify the Released Parties against any and all claims, damages, liabilities, costs, and expenses arising from use of his/her Entry, his/her Registration form, the Prize or any portion(s) thereof; and (v) agrees to the publication, reproduction and/or other use of his/her name, address, voice, statements about the Giveaway and/or photograph or other likeness without further notice or compensation, in any publicity or advertisement carried out by or on behalf of the Sponsor in any manner or medium whatsoever, including print, broadcast or the internet, worldwide and in perpetuity. IMPORTANT NOTE: The Sponsor may require, in its sole and absolute discretion, that an eligible winner sign and return the Sponsor’s form of declaration and release prior to being confirmed as the winner.

If a potential winner: (a) fails to correctly answer the skill-testing question; (b) as applicable, fails to return the properly executed Giveaway documents within the specified time; (c) cannot accept (or is unwilling to accept) the Prize (as awarded) for any reason; and/or (d) is determined to be in violation of these Rules (all as determined by the Sponsor in its sole and absolute discretion); then he/she will be disqualified (and will forfeit all rights to the applicable Prize) and the Sponsor reserves the right, in its sole and absolute discretion and time permitting, to randomly select an alternate potential winner, as applicable, in accordance with the procedure outlined above, with the necessary amendments (in which case the foregoing provisions of this section shall apply to such newly selected potential winner).

## 12. GENERAL CONDITIONS:

All Entries become the property of the Sponsor. This Giveaway is subject to all applicable federal, provincial and municipal laws. The decisions of the Sponsor with respect to all aspects of this Giveaway are final and binding on all entrants without right of appeal. ANYONE DEEMED BY THE SPONSOR TO BE IN VIOLATION OF THE LETTER AND/OR SPIRIT OF THESE RULES FOR ANY REASON IS SUBJECT TO DISQUALIFICATION IN THE SOLE AND ABSOLUTE DISCRETION OF THE SPONSOR AT ANY TIME.

If due to printing, production, online, internet, computer or other error of any kind, more Prizes are claimed than intended to be distributed or awarded according to these Rules then, in addition to having the right to terminate the Giveaway immediately, Sponsor reserves the right to conduct a random drawing from amongst all eligible Prize claimants whose claims have not yet been redeemed to award the correct number of Prizes (as stated in these Rules). The Prizes may stop being awarded in the event the Sponsor becomes aware of such an error. In no event will the Sponsor be liable to award more than the number of Prizes as provided in these Rules.

The Released Parties will not be liable for: (i) any failure of any other website or platform during the Giveaway; (ii) any technical malfunction or other problems of any nature whatsoever, including, without limitation, those relating to the telephone network or lines, computer on-line systems, servers, access providers, computer equipment or software; (iii) the failure of any Entry, Registration form, and/or other information to be received, captured or recorded for any reason whatsoever, including, but not limited to, technical problems or traffic congestion on the Internet or at any website; (iv) any injury or damage to an entrant’s or any other person’s computer or other device related to or resulting from participating in the Giveaway; (v) anyone being incorrectly and/or mistakenly identified as the winner or eligible winner; and/or (vi) any combination of the above.

The Sponsor reserves the right, at its sole discretion, to cancel, withdraw, amend or suspend the Giveaway (or to amend these Rules) in any way, in the event of any cause beyond the reasonable control of the Sponsor that interferes with the proper conduct of the Giveaway as contemplated by these Rules, including, without limitation, any error, problem, computer virus, bugs, tampering, unauthorized intervention, fraud or failure of any kind whatsoever. Any attempt to undermine the legitimate operation of the Giveaway in any way (as determined by Sponsor in its sole and absolute discretion) may be a violation of criminal and civil laws and should such an attempt be made, the Sponsor reserves the right to seek remedies and damages to the fullest extent permitted by law. The Sponsor reserves the right to cancel, amend or suspend the Giveaway, or to amend these Rules, in any way without prior notice or obligation, in the event of any accident, printing, administrative, or other error of any kind, or for any other reason whatsoever. The Sponsor reserves the right, in its sole and absolute discretion, to administer an alternate test of skill as it deems appropriate based on the circumstances and/or to comply with applicable law.

The Sponsor reserves the right, at its sole discretion, to adjust any of the dates, timeframes and/or other Giveaway mechanics stipulated in these Rules, to the extent deemed necessary by Sponsor, for purposes of verifying compliance by any entrant, Entry and/or other information with these Rules, or as a result of any technical or other problems, or in light of any other circumstances which, in the opinion of the Sponsor, in its sole and absolute discretion, affect the proper administration of the Giveaway as contemplated in these Rules, or for any other reason.

By entering this Giveaway, each entrant expressly consents to the Sponsor, its agents and/or representatives, storing, sharing and using the personal information submitted for the purpose of administering the Giveaway and in accordance with Sponsor’s privacy policy (available at: https://www.henkel-northamerica.com/privacy-statement-na ).This section does not limit any other consent(s) that an individual may provide the Sponsor or others in relation to the collection, use and/or disclosure of their personal information.

In the event of any discrepancy or inconsistency between the terms and conditions of these English Rules and disclosures or other statements contained in any Giveaway-related materials, including, but not limited to, the French version of these Rules, the Website, point of sale, television, print or online advertising and/or any instructions or interpretations of these Rules given by any representative of the Sponsor, the terms and conditions of these English Rules shall prevail, govern and control to the fullest extent permitted by law. Standard data rates apply to participants who choose to participate in the Giveaway via a mobile device. Please contact your service provider for pricing and service plan information and rates before mobile device participation.

The invalidity or unenforceability of any provision of these Rules shall not affect the validity or enforceability of any other provision.  In the event that any provision is determined to be invalid or otherwise unenforceable or illegal, these Rules shall otherwise remain in effect and shall be construed in accordance with the terms as if the invalid or illegal provision were not contained herein.

To the fullest extent permitted by applicable law, all issues and questions concerning the construction, validity, interpretation and enforceability of these Rules or the rights and obligations of participants, Sponsor or any of the other the Released Parties in connection with the Contest will be governed by and construed in accordance with the domestic laws of the Province of Ontario and the federal laws of Canada applicable therein, without giving effect to any choice of law or conflict of law rules or provisions that would cause the application of any other jurisdiction’s laws.  The parties hereby consent to the exclusive jurisdiction and venue of the courts located in Ontario in any action to enforce (or otherwise relating to) these Rules or relating to the Contest.
`;
