import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    padding: 64px 24px;
  }

  @media (min-width: 1024px) {
    padding: 64px 48px;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1144px;

  @media (min-width: 1024px) {
    justify-content: space-between;
    align-items: center;
    min-height: 444px;
    padding-left: 32px;
  }

  @media (min-width: 1400px) {
    padding-left: 0;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  text-align: center;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 360px;
  }

  @media (min-width: 1400px) {
    max-width: 444px;
  }
`;

export const SliderContainer = styled.div`
  @media (max-width: 1023px) {
    margin-top: 60px;
  }

  @media (min-width: 1024px) {
    display: none;
  }

  .slick-dots {
    bottom: -20px;
  }
`;

export const CtaContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    margin-top: 40px;
  }

  @media (min-width: 1024px) {
    margin-top: 42px;
  }

  @media (min-width: 1400px) {
    display: ${(p) => !p.$isShowCtaOnDesktop && 'none'};
  }
`;

export const GridContainer = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 480px;
    height: 258px;
  }

  @media (min-width: 1120px) {
    max-width: 572px;
    height: 308px;
  }
`;

export const GridRow = styled.div`
  display: flex;
  width: 100%;

  &.--first {
    justify-content: space-between;
  }

  &.--third {
    justify-content: space-around;
    max-width: 75%;
  }
`;

export const LogoContainer = styled.div`
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  @media (max-width: 1023px) {
    height: 72px;

    svg {
      width: 120px;
    }
  }

  @media (min-width: 1024px) {
    width: 101px;

    svg {
      width: 100%;
    }

    &.--clubh {
      flex: 1;

      svg {
        width: 185px;
      }
    }
  }

  @media (min-width: 1120px) {
    width: 120px;
  }
`;
