import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import * as yup from "yup";
import { isEmpty } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Container,
  Main,
  Form,
  Fields,
  Divider,
  Cart,
  CartWrapper,
  CartToggler,
  SubmitContainer,
  BackToCart,
  SubmitButtonContainer,
  ShippingContainer,
  ShippingCost,
} from "./CheckoutView.style";

import { Layout } from "../../components/Layout/Layout";
import { Header } from "../../components/Header/Header";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import { Footer } from "../../components/Footer/Footer";
import { Loading } from "../../components/Loading/Loading";

import { Typography } from "../../ui/Typography/Typography";
import { TextInput } from "../../ui/TextInput/TextInput";
import { Select } from "../../ui/Select/Select";
import { Radio } from "../../ui/Radio/Radio";
import { Checkbox } from "../../ui/Checkbox/Checkbox";
import { Button } from "../../ui/Button/Button";
import { CheckoutCart } from "../../components/CheckoutCart/CheckoutCart";
import { Link } from "../../components/Link/Link";
// import { apiDomain, apiSiteId } from "../../config";
// import { brands } from "../../config/brands";
import {
  toast,
  ToastNotification,
} from "../../ui/ToastNotification/ToastNotification";
import { GET_CART_DETAILS, CHECKOUT } from "../RewardsView/rewardsViewConfig";
import { shippingOptions, provinces } from "../../config/checkout";

const address = yup.object({
  str1: yup.string().required().label("address line 1").default(""),
  str2: yup.string().label("address line 2").default(""),
  city: yup.string().required().label("city").default(""),
  postal: yup.string().required().label("postal code").default(""),
  country: yup.string().required().label("country").default("CA"),
  state: yup.string().required().label("province").default(""),
  data: yup.object({
    firstname: yup.string().required().label("first name").default(""),
    lastname: yup.string().required().label("last name").default(""),
    phone: yup.string().required().label("phone").default(""),
    addressType: yup.string().required().label("address type").default(""),
  }),
});

const shipping = yup.object({
  method: yup
    .string()
    .required()
    .label("shipping method")
    .default(shippingOptions.find((o) => o.default).id),
  address,
});

const billing = yup.object({
  method: yup.string().required().label("billing method").default("points"),
  // data: yup.object({
  //   card: yup.string().label("card number").default(null),
  //   cvv2: yup.string().label("cvv2").default(null),
  //   expiresYear: yup.string().label("expires year").default(null),
  //   expiresMonth: yup.string().label("expires month").default(null),
  // }),
  // address, // this will be passed in right before submitting
});

const schema = yup.object({
  shipping,
  billing,
  terms: yup
    .boolean()
    .oneOf([true], "Field must be checked")
    .required()
    .label("terms and conditions")
    .default(false),
});

export const CheckoutView = () => {
  const intl = useIntl();
  const [cartIsShow, setCartIsShow] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: schema.default(),
  });
  const {
    loading: cartLoading,
    error: cartError,
    data: cartData,
  } = useQuery(GET_CART_DETAILS, {
    variables: {
      locale: intl.locale,
    },
  });

  const cart = cartData?.me?.cart;
  const pointsBalance = cartData?.me?.pointsBalance;
  const cartItems = cart?.items ?? [];

  const navigate = useNavigate();

  const [checkout] = useMutation(CHECKOUT, {
    refetchQueries: ["GetHeaderCartData", "GetCartDetails"],
  });

  const data = watch();

  useEffect(() => {
    if (cartData?.me?.user?._id) {
      const user = cartData?.me?.user;
      setValue("shipping.address.data.firstname", user?.firstname ?? "");
      setValue("shipping.address.data.lastname", user?.lastname ?? "");
      setValue("shipping.address.data.phone", user?.phone) ?? "";
      setValue("shipping.address.str1", user?.salon?.address?.str1 ?? "");
      setValue("shipping.address.str2", user?.salon?.address?.str2 ?? "");
      setValue("shipping.address.city", user?.salon?.address?.city ?? "");
      setValue("shipping.address.state", user?.salon?.address?.state ?? "");
      setValue("shipping.address.postal", user?.salon?.address?.postal ?? "");
      setValue(
        "shipping.address.data.addressType",
        user?.salon?.address?.data?.addressType ?? ""
      );
    }
  }, [cartData?.me?.user?._id]);

  useEffect(() => {
    if (
      cartItems?.length === 0 ||
      pointsBalance < cart?.totals?.pointsTotal?.amount ||
      cartData?.me?.user?._id == null
    ) {
      navigate(`/${intl.locale}/cart`, { replace: true });
    }
  }, []);

  if (cartLoading) {
    return <Loading global />;
  }

  const onSubmit = (data) => {
    const payload = { ...data };
    payload.billing.address = { ...payload.shipping.address };
    return checkout({
      variables: { data: payload, locale: intl.locale },
    })
      .then((results) => {
        console.info({ results });
        navigate(`/${intl.locale}/order-confirmation`, {
          replace: true,
          state: { orderId: results?.data?.cartPlaceOrder?.fid },
        });
      })
      .catch((err) => {
        toast.error(
          <ToastNotification>
            <FormattedMessage defaultMessage="An error occurred while checking out.  Please refresh and try again." />
          </ToastNotification>
        );
        Sentry.captureException(err);
        console.error({ err, type: "checkout" });
      });
  };

  return (
    <Layout>
      <Header />
      <Container>
        <Main>
          <Breadcrumbs
            breadcrumbs={[
              {
                to: "/",
                label: intl.formatMessage({ defaultMessage: "Home" }),
              },
              {
                to: null,
                label: intl.formatMessage({ defaultMessage: "Checkout" }),
              },
            ]}
          />
          <PageTitle
            title={intl.formatMessage({ defaultMessage: "Checkout" })}
          />

          <Cart className="--mobile">
            <CartWrapper>
              <CartToggler
                type="button"
                $isActive={cartIsShow}
                onClick={() => setCartIsShow(!cartIsShow)}
              >
                <Typography type="text-small" color="#000">
                  <FormattedMessage defaultMessage="Total: 18,000 PTS" />
                </Typography>

                <span style={{ display: "flex", alignItems: "center" }}>
                  <Typography type="text-small">
                    {cartIsShow ? (
                      <FormattedMessage defaultMessage="Hide Cart" />
                    ) : (
                      <FormattedMessage defaultMessage="Show Cart" />
                    )}
                  </Typography>
                  &nbsp;&nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 9 9"
                    width={9}
                    height={9}
                  >
                    <path
                      fill="currentColor"
                      d={
                        cartIsShow
                          ? "M.35 5.15v-1.3h8.3v1.3H.35Z"
                          : "M3.808 8.748V5.474l-.282-.287H.251V3.798h3.24l.314-.311V.248h1.39v3.243l.31.312H8.75v1.388H5.478l-.282.287v3.274l-1.388-.004Z"
                      }
                    />
                  </svg>
                </span>
              </CartToggler>
              {cartIsShow && <CheckoutCart cart={cart} />}
            </CartWrapper>
          </Cart>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Typography type="h3" style={{ marginBottom: 20 }}>
              <FormattedMessage defaultMessage="Shipping Details" />
            </Typography>

            <Fields>
              <Controller
                name="shipping.address.data.firstname"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label={intl.formatMessage({ defaultMessage: "First Name" })}
                    error={errors?.shipping?.address?.data?.firstname}
                  />
                )}
              />
              <Controller
                name="shipping.address.data.lastname"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label={intl.formatMessage({ defaultMessage: "Last Name" })}
                    error={errors?.shipping?.address?.data?.lastname}
                  />
                )}
              />
              <Controller
                name="shipping.address.str1"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label={intl.formatMessage({
                      defaultMessage: "Address 1",
                    })}
                    error={errors?.shipping?.address?.str1}
                  />
                )}
              />
              <Controller
                name="shipping.address.str2"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label={intl.formatMessage({
                      defaultMessage: "Address 2 (Optional)",
                    })}
                    error={errors?.shipping?.address?.str2}
                  />
                )}
              />
              <Controller
                name="shipping.address.state"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    control={control}
                    label={intl.formatMessage({ defaultMessage: "Province" })}
                    options={provinces}
                  />
                )}
                error={errors?.shipping?.address?.state}
              />
              <Controller
                name="shipping.address.city"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label={intl.formatMessage({
                      defaultMessage: "City",
                    })}
                    error={errors?.shipping?.address?.city}
                  />
                )}
              />
              <Controller
                name="shipping.address.postal"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label={intl.formatMessage({
                      defaultMessage: "Postal Code",
                    })}
                    error={errors?.shipping?.address?.postal}
                  />
                )}
              />
              <Controller
                name="shipping.address.data.addressType"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    control={control}
                    label={intl.formatMessage({
                      defaultMessage: "Address Type",
                    })}
                    options={[
                      {
                        value: "Business",
                        label: intl.formatMessage({
                          defaultMessage: "Business",
                        }),
                      },
                      {
                        value: "Residential",
                        label: intl.formatMessage({
                          defaultMessage: "Residential",
                        }),
                      },
                    ]}
                    style={{ paddingBottom: 0 }}
                    error={errors?.shipping?.address?.data?.addressType}
                  />
                )}
              />
              <Controller
                name="shipping.address.data.phone"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label={intl.formatMessage({ defaultMessage: "Phone" })}
                    error={errors?.shipping?.address?.data?.phone}
                  />
                )}
              />
            </Fields>

            <Divider style={{ marginTop: 26, marginBottom: 41 }} />

            <Typography type="h3" style={{ marginBottom: 20 }}>
              <FormattedMessage defaultMessage="Shipping Method" />
            </Typography>

            <ShippingContainer>
              <Controller
                name="shipping.method"
                control={control}
                render={({ field }) =>
                  shippingOptions.map((option) => {
                    return (
                      <Radio
                        key={option.id}
                        {...field}
                        checked={field.value === option.id ? true : false}
                        onChange={(e) => {
                          // console.info(JSON.stringify(e.target.checked));
                          field.onChange(e.target.checked ? option.id : "");
                        }}
                        label={option.description(intl)}
                        style={{ maxWidth: 330 }}
                      />
                    );
                  })
                }
              />
              <ShippingCost>
                <Typography type="h4">$0.00</Typography>
              </ShippingCost>
            </ShippingContainer>

            <Divider />

            <Typography type="h3" style={{ marginBottom: 13 }}>
              <FormattedMessage defaultMessage="Payment Method" />
            </Typography>
            <Typography type="text-primary">
              <FormattedMessage defaultMessage="No payment method required." />
            </Typography>

            <Divider />

            <Controller
              name="terms"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  label={intl.formatMessage(
                    {
                      defaultMessage:
                        "By clicking 'Place Order', I agree to be bound by the {termsOfUse}, and I acknowledge and consent to the practices described in Henkel’s {privacyPolicy}.",
                    },
                    {
                      termsOfUse: (
                        <a
                          href={intl.formatMessage({
                            defaultMessage:
                              "https://www.henkel-northamerica.com/terms-of-use-na",
                          })}
                          target="_blank"
                          style={{ color: "#000" }}
                        >
                          {intl.formatMessage({
                            defaultMessage: "Henkel Terms of Use",
                          })}
                        </a>
                      ),
                      privacyPolicy: (
                        <a
                          href={intl.formatMessage({
                            defaultMessage:
                              "https://www.henkel-northamerica.com/privacy-statement-na",
                          })}
                          target="_blank"
                          style={{ color: "#000" }}
                        >
                          {intl.formatMessage({
                            defaultMessage: "Privacy Policy",
                          })}
                        </a>
                      ),
                    }
                  )}
                  error={errors?.terms}
                />
              )}
            />

            <SubmitContainer>
              <BackToCart as={Link} to="/cart">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 14 14"
                  width={14}
                  height={14}
                >
                  <path
                    fill="#8f95a5"
                    d="m10.305 1.708-5.11 5.1v.389l5.11 5.1-1.06 1.055-5.549-5.539V6.192L9.245.649l1.06 1.059Z"
                  />
                </svg>
                <Typography type="text-small">
                  <FormattedMessage defaultMessage="Back to Cart" />
                </Typography>
              </BackToCart>

              <SubmitButtonContainer>
                <Button
                  variant="auth"
                  arrow={false}
                  // disabled={!isEmpty(errors) || isSubmitting || !isValid}
                >
                  <FormattedMessage defaultMessage="Place order" />
                </Button>
              </SubmitButtonContainer>
            </SubmitContainer>
          </Form>
        </Main>

        <Cart className="--desktop">
          <CheckoutCart cart={cart} />
        </Cart>
      </Container>
      <Footer />
    </Layout>
  );
};
