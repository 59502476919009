import React, { useContext } from "react";
import { ScrollRestoration } from "react-router-dom";
import { Root } from "./Layout.style";
import { Loading } from "../Loading/Loading";
import useScript from "../../hooks/useScript";
import { UserContext } from "../../context/UserContext";
import { isLocalEnv, isMasterEnv, isBrowser } from "../../config";
import { use } from "react";

export const Layout = ({ children }) => {
  const { initialized } = useContext(UserContext);

  if (!isLocalEnv) {
    useScript("https://static.klaviyo.com/onsite/js/VQidKP/klaviyo.js");
    useScript("https://cdn.cookielaw.org/scripttemplates/otSDKStub.js", {
      "data-domain-script": `1cc062c8-0a87-46f9-9d43-eeec055c14de${
        !isMasterEnv ? "-test" : ""
      }`,
    });
    if (isBrowser) {
      window.OptanonWrapper = () => {};
    }
  }

  return !initialized ? (
    <Loading />
  ) : (
    <Root>
      <ScrollRestoration />
      {children}
    </Root>
  );
};
