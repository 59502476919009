import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Controller, useFieldArray } from "react-hook-form";

import {
  DistributorList,
  DistributorItem,
  DistributorHeader,
  DistributorTitle,
  DistributorTooltip,
  DistributorRemoveButton,
} from "./AuthRegister.style";

import { TextInput } from "../../ui/TextInput/TextInput";
import { MaskedInput } from "../../ui/MaskedInput/MaskedInput";
import { Select } from "../../ui/Select/Select";
import { Icon } from "../../ui/Icon/Icon";
import { Button } from "../../ui/Button/Button";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../../ui/Tooltip/Tooltip";
import { distributors } from "../../config";

export function Step1({ control, errors }) {
  const intl = useIntl();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "distributor",
  });

  return (
    <div style={{ width: "100%", marginBottom: 28 }}>
      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            label={intl.formatMessage({
              defaultMessage: "First Name",
            })}
            error={errors?.firstName}
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            label={intl.formatMessage({
              defaultMessage: "Last Name",
            })}
            error={errors?.lastName}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            type="email"
            label={intl.formatMessage({
              defaultMessage: "Email",
            })}
            autoComplete="email"
            error={errors?.email}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            type="password"
            label={intl.formatMessage({
              defaultMessage: "Password",
            })}
            autoComplete="new-password"
            error={errors?.password}
          />
        )}
      />
      <Controller
        name="mobile"
        render={({ field: { onChange, onBlur } }) => (
          <MaskedInput
            mask="000-000-0000"
            label={intl.formatMessage({
              defaultMessage: "Mobile Number",
            })}
            error={errors?.mobile}
            onBlur={onBlur}
            onChange={(v) => onChange(v)}
          />
        )}
        control={control}
      />
      <Controller
        name="profession"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            control={control}
            label={intl.formatMessage({
              defaultMessage: "Profession",
            })}
            options={[
              {
                value: "Owner",
                label: intl.formatMessage({
                  defaultMessage: "Owner",
                }),
              },
              {
                value: "Chair Renter",
                label: intl.formatMessage({
                  defaultMessage: "Chair Renter",
                }),
              },
              {
                value: "Stylist",
                label: intl.formatMessage({
                  defaultMessage: "Stylist",
                }),
              },
              {
                value: "Manager",
                label: intl.formatMessage({
                  defaultMessage: "Manager",
                }),
              },
            ]}
            error={errors?.profession}
          />
        )}
      />

      <DistributorList>
        {fields.map((item, index) => (
          <DistributorItem key={item.id}>
            <DistributorHeader>
              <span style={{ display: "flex", alignItems: "center" }}>
                <DistributorTitle>
                  <FormattedMessage defaultMessage="Distributor" /> {index + 1}
                </DistributorTitle>
                {/* {index === 0 && (
                  <Tooltip placement="top-start">
                    <TooltipTrigger>
                      <DistributorTooltip>
                        <Icon icon="info-circle" />
                      </DistributorTooltip>
                    </TooltipTrigger>
                    <TooltipContent>blabla</TooltipContent>
                  </Tooltip>
                )} */}
              </span>

              {index > 0 && (
                <DistributorRemoveButton
                  type="button"
                  onClick={() => remove(index)}
                >
                  <Icon icon="trash-can" />
                </DistributorRemoveButton>
              )}
            </DistributorHeader>
            <Controller
              name={`distributor.${index}._id`}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  control={control}
                  label={intl.formatMessage({
                    defaultMessage: "Distributor",
                  })}
                  options={distributors()}
                  error={errors?.distributor?.[index]?._id}
                />
              )}
            />
            <Controller
              name={`distributor.${index}.accountNumber`}
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  label={intl.formatMessage({
                    defaultMessage: "Distributor Account Number",
                  })}
                  error={errors?.distributor?.[index]?.accountNumber}
                />
              )}
            />
          </DistributorItem>
        ))}
      </DistributorList>

      {fields.length < 3 && (
        <Button
          variant="action"
          arrow={false}
          type="button"
          onClick={() => {
            append({ _id: "", accountNumber: "" });
            // fields.map((f, idx) => {
            //   console.info({ f });
            //   setTimeout(() => {
            //     trigger(`distributor.${idx}.name`);
            //     trigger(`distributor.${idx}.accountNumber`);
            //   }, 100);
            // });
          }}
        >
          <Icon icon="plus" style={{ marginRight: 6, fontSize: 16 }} />
          <span>
            <FormattedMessage defaultMessage="add a distributor" />
          </span>
        </Button>
      )}
    </div>
  );
}
