import React, { useContext } from 'react';
import Slider from 'react-slick';
import { FormattedMessage } from 'react-intl';

import {
  Root,
  Container,
  TextContainer,
  SliderContainer,
  CtaContainer,
  GridRow,
  LogoContainer,
  GridContainer,
} from './BeautyBrands.style';

import { brands } from '../../../config/brands';

import { Typography } from '../../../ui/Typography/Typography';
import { Button } from '../../../ui/Button/Button';
import { Link } from '../../Link/Link';

import Schwarzkopf from '../../../assets/images/brands/logo-schwarzkopf.svg?react';
import Joico from '../../../assets/images/brands/logo-joico.svg?react';
import Kenra from '../../../assets/images/brands/logo-kenra-professional.svg?react';
import Deva from '../../../assets/images/brands/logo-deva-curl.svg?react';
import Sexyhair from '../../../assets/images/brands/logo-sexyhair.svg?react';
import ClubH from '../../../assets/clubh-logo.svg?react';
import Pravana from '../../../assets/images/brands/logo-pravana.svg?react';
import Alterna from '../../../assets/images/brands/logo-alterna.svg?react';
import Stmnt from '../../../assets/images/brands/logo-stmnt.svg?react';
import Authentic from '../../../assets/images/brands/logo-authentic-beauty-concept.svg?react';

import { UserContext } from '../../../context/UserContext';

const sliderSettings = {
  arrows: false,
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 3000,
  slidesToShow: 3,
  slidesToScroll: 3,
};

const BeautyBrands = ({
  isShowCtaOnDesktop = true,
  linkBrands = true,
  ctaTextLoggedIn = '',
  ctaUrlLoggedIn = '',
  ctaTextLoggedOut = '',
  ctaUrlLoggedOut = '',
}) => {
  const { user } = useContext(UserContext);

  return (
    <Root>
      <Container>
        <TextContainer>
          <Typography
            as="h2"
            type="h2"
            style={{ maxWidth: 390, margin: '0 auto' }}
          >
            <FormattedMessage defaultMessage="All Your Favorite Brands, Now in One Exciting Loyalty Program" />
          </Typography>
          <Typography as="p" type="text-primary" style={{ marginTop: 28 }}>
            <FormattedMessage defaultMessage="Explore the vibrant world of Henkel Beauty Care, where a diverse array of brands come together to reward you at every turn. Welcome to Club H – where your loyalty earns more!" />
            {/* <br />
            <br />
            <FormattedMessage
              defaultMessage="Mark the date: Club H registration opens{br}January 1, 2024."
              values={{
                p: (...chunks) => <p>{chunks}</p>,
                br: <br />,
              }}
            /> */}
          </Typography>

          <SliderContainer>
            <Slider {...sliderSettings}>
              {brands
                .sort(function (a, b) {
                  if (a.order < b.order) return -1;
                  if (a.order > b.order) return 1;
                  return 0;
                })
                .map((brand) => (
                  <LogoContainer key={brand.label}>{brand.icon}</LogoContainer>
                ))}
            </Slider>
          </SliderContainer>

          <CtaContainer $isShowCtaOnDesktop={isShowCtaOnDesktop}>
            {user ? (
              <Button as={Link} to={ctaUrlLoggedIn}>
                {ctaTextLoggedIn}
              </Button>
            ) : (
              <Button as={Link} to={ctaUrlLoggedOut}>
                {ctaTextLoggedOut}
              </Button>
            )}
          </CtaContainer>
        </TextContainer>

        <GridContainer>
          <GridRow className="--first">
            <LogoContainer
              as={linkBrands ? Link : 'div'}
              to="/brands/schwarzkopf"
            >
              <Schwarzkopf />
            </LogoContainer>
            <LogoContainer as={linkBrands ? Link : 'div'} to="/brands/joico">
              <Joico />
            </LogoContainer>
            <LogoContainer as={linkBrands ? Link : 'div'} to="/brands/kenra">
              <Kenra />
            </LogoContainer>
            <LogoContainer as={linkBrands ? Link : 'div'} to="/brands/devacurl">
              <Deva />
            </LogoContainer>
          </GridRow>

          <GridRow>
            <LogoContainer as={linkBrands ? Link : 'div'} to="/brands/sexyhair">
              <Sexyhair />
            </LogoContainer>
            <LogoContainer className="--clubh">
              <ClubH />
            </LogoContainer>
            <LogoContainer as={linkBrands ? Link : 'div'} to="/brands/pravana">
              <Pravana />
            </LogoContainer>
          </GridRow>

          <GridRow className="--third">
            <LogoContainer as={linkBrands ? Link : 'div'} to="/brands/alterna">
              <Alterna />
            </LogoContainer>
            <LogoContainer as={linkBrands ? Link : 'div'} to="/brands/stmnt">
              <Stmnt />
            </LogoContainer>
            <LogoContainer
              as={linkBrands ? Link : 'div'}
              to="/brands/authenticbeautyconcept"
            >
              <Authentic />
            </LogoContainer>
          </GridRow>
        </GridContainer>

        {/* <Grid>
          {[...brands, { label: 'ClubH', icon: <ClubH /> }].map(
            (brand, idx) => (
              <LogoContainer
                key={`${idx}-${brand.label}`}
                as={linkBrands && brand.slug ? Link : 'div'}
                to={linkBrands && brand.slug && `/brands/${brand.slug}`}
                className={brand.label === 'ClubH' && '--clubh'}
                style={{ gridArea: brand.slug || brand.label }}
              >
                {brand.icon}
              </LogoContainer>
            )
          )}
        </Grid> */}
      </Container>
    </Root>
  );
};

export { BeautyBrands };
