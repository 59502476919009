import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Link, NavLink } from "../Link/Link";

import {
  Root,
  ScrollContainer,
  NavList,
  NavItem,
  Container,
  BottomNav,
  BottomNavItem,
} from "./MobileMenu.style";

import { useLockBodyScroll } from "../../hooks/useLockBodyScroll";
import { UserContext } from "../../context/UserContext";

import { Button } from "../../ui/Button/Button";
import { Social } from "../Social/Social";
import { HeaderUserBadge } from "../HeaderUserBadge/HeaderUserBadge";
import { Typography } from "../../ui/Typography/Typography";

export function MobileMenu({ topOffset, imageSrc, pts, firstname }) {
  const intl = useIntl();
  const locale = intl.locale;
  const navigate = useNavigate();
  useLockBodyScroll();
  const { user, signOut } = useContext(UserContext);

  return (
    <Root $topOffset={topOffset}>
      <ScrollContainer>
        <NavList>
          <NavItem>
            <NavLink
              to="/rewards"
              className={({ isActive }) => (isActive ? "--isActive" : "")}
            >
              <Typography type="h2">
                <FormattedMessage defaultMessage="Rewards" />
              </Typography>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="/offers"
              className={({ isActive }) => (isActive ? "--isActive" : "")}
            >
              <Typography type="h2">
                <FormattedMessage defaultMessage="Offers" />
              </Typography>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="/experience"
              className={({ isActive }) => (isActive ? "--isActive" : "")}
            >
              <Typography type="h2">
                <FormattedMessage defaultMessage="Experiences" />
              </Typography>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="/membership-benefits"
              className={({ isActive }) => (isActive ? "--isActive" : "")}
            >
              <Typography type="h2">
                <FormattedMessage defaultMessage="Membership" />
              </Typography>
            </NavLink>
          </NavItem>
          {user && (
            <NavItem>
              <NavLink
                to="/dashboard"
                className={({ isActive }) => (isActive ? "--isActive" : "")}
              >
                <Typography type="h2">
                  <FormattedMessage defaultMessage="My Dashboard" />
                </Typography>
              </NavLink>
            </NavItem>
          )}
          {user && (
            <NavItem>
              <NavLink
                to="/dashboard/my-account"
                className={({ isActive }) => (isActive ? "--isActive" : "")}
              >
                <Typography type="h2">
                  <FormattedMessage defaultMessage="My Account" />
                </Typography>
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              to="https://clubhrewards.zendesk.com/"
              className={({ isActive }) => (isActive ? "--isActive" : "")}
            >
              <Typography type="h2">
                <FormattedMessage defaultMessage="FAQ" />
              </Typography>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink locale={locale === "fr-CA" ? "en-CA" : "fr-CA"}>
              <Typography type="h2">
                {locale === "fr-CA" ? (
                  <FormattedMessage defaultMessage="English" />
                ) : (
                  <FormattedMessage defaultMessage="French" />
                )}
              </Typography>
            </NavLink>
          </NavItem>
          <NavItem>
            {user ? (
              <button
                onClick={async () => {
                  await signOut();
                  navigate("/");
                }}
              >
                <Typography type="h2">
                  <FormattedMessage defaultMessage="Log Out" />
                </Typography>
              </button>
            ) : (
              <NavLink to="/auth">
                <Typography type="h2">
                  <FormattedMessage defaultMessage="Log In" />
                </Typography>
              </NavLink>
            )}
          </NavItem>
        </NavList>

        <Container>
          {user ? (
            <HeaderUserBadge
              url="/dashboard"
              imageSrc={imageSrc}
              name={firstname}
              pts={pts}
            />
          ) : (
            <Button>
              <FormattedMessage defaultMessage="Join now" />
            </Button>
          )}
        </Container>

        <Container className="--social">
          <Social />
        </Container>
      </ScrollContainer>

      <BottomNav>
        <BottomNavItem>
          <a
            href="https://www.henkel-northamerica.com/privacy-statement-na"
            target="_blank"
          >
            <Typography type="text-small">
              <FormattedMessage defaultMessage="Privacy Policy" />
            </Typography>
          </a>
        </BottomNavItem>
        <BottomNavItem>
          <button
            type="button"
            onClick={() => window.Optanon.ToggleInfoDisplay()}
          >
            <Typography type="text-small">
              <FormattedMessage defaultMessage="Cookies" />
            </Typography>
          </button>
        </BottomNavItem>
        <BottomNavItem>
          <Link to="/">
            <Typography type="text-small">
              <FormattedMessage defaultMessage="Accessibility Policy" />
            </Typography>
          </Link>
        </BottomNavItem>
        <BottomNavItem>
          <a
            href="https://www.henkel-northamerica.com/privacy-statement-na#analytics"
            target="_blank"
          >
            <Typography type="text-small">
              <FormattedMessage defaultMessage="About Ads" />
            </Typography>
          </a>
        </BottomNavItem>
        <BottomNavItem>
          <Link to="/">
            <Typography type="text-small">
              <FormattedMessage defaultMessage="T&Cs" />
            </Typography>
          </Link>
        </BottomNavItem>
        <BottomNavItem>
          <Link to="/">
            <Typography type="text-small">
              <FormattedMessage defaultMessage="Support" />
            </Typography>
          </Link>
        </BottomNavItem>
      </BottomNav>
    </Root>
  );
}
