import styled from 'styled-components';
import { motion } from 'framer-motion';

export const TabsContainer = styled.div`
  @media (max-width: 1023px) {
    display: none;
    padding: 32px 24px;
    border-bottom: 1px solid #dcdfe9;
  }
`;

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 1023px) {
    padding: 56px 0;
  }

  @media (min-width: 1024px) {
    padding: 64px 0;
  }
`;

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 48px 16px;

  @media (min-width: 1024px) {
    display: none;
  }
`;
